@import "/src/style/variables.scss";

.card {
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  border: 1px solid $border-color;
  color: $main-color;
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
  height: 340px;
  align-items: center;

  @media screen and (max-width: $mobile-bp) {
    margin: 20px;
    width: 250px;
    text-align: center;
  }
  &:hover {
    background-color: $main-color;
    box-shadow: 4px 17px 21px 7px rgba(0, 0, 0, 0.27);
    color: $second-main-color;
    .card-logo:after {
      background-color: $second-main-color;
    }
  }
  .card-logo {
    padding-bottom: 50px;
    font-weight: bold;
    position: relative;
    text-decoration: none;
    font-weight: bold;
  }
  .card-logo:after {
    content: "";
    position: absolute;
    height: 1.5px;
    background-color: $main-color;
    width: 40%;
    left: 30%;
    bottom: 0px;
  }
  .card-description {
    margin-top: 50px;
    max-width: 130px;
  }
  @media screen and (max-width: $mobile-bp) {
    max-width: 220px;
  }
  .card-field {
    margin: 40px 0;
  }
}
