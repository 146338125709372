.contributing-to-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-section-container {
  color: white;
  margin-bottom: 20px;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
