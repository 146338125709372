@import "/src/style/variables.scss";

.main-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 250px;

  .layout-content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex: 1;
    margin-top: 30px;
  }
}
