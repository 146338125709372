$main-color: black;
$second-main-color: white;
$brand-color: #ffff;
$brand-color-2: #bff8fd;
$second-brand-color: #3482c3;
$header-background-color: #0b71c7;
$main-background-color: whitesmoke;
$border-main-color: white;
$mobile-bp: 768px;
$tablet-mode: 1024px;
$green-main-color: rgba(39, 141, 117, 255);
$border-color: #7d0101;
