@import "/src/style/variables.scss";

.icon-container {
  .icon-elements {
    display: flex;
    justify-content: center;
    padding: 0;
  }
  .icon-item {
    list-style: none;
    padding-right: 20px;
    cursor: pointer;
  }
  .icon-link {
    color: $main-color;
  }
}
